// Generated by Framer (4b8bcae)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/UkS9aeQXr";

const enabledGestures = {qBmqbobG8: {hover: true}};

const cycleOrder = ["qBmqbobG8"];

const serializationHash = "framer-qSG7z"

const variantClassNames = {qBmqbobG8: "framer-v-gze6se"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, link1, tap, width, ...props}) => { return {...props, FflsjHJff: link ?? props.FflsjHJff, rhVAnbOgT: link1 ?? props.rhVAnbOgT ?? "Link", WFNbhH5HS: tap ?? props.WFNbhH5HS} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;link?: string;link1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WFNbhH5HS, FflsjHJff, rhVAnbOgT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qBmqbobG8", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1bgwsw4 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (WFNbhH5HS) {
const res = await WFNbhH5HS(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={FflsjHJff} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-gze6se", className, classNames)} framer-ys42lo`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"qBmqbobG8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1bgwsw4} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"qBmqbobG8-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h5 className={"framer-styles-preset-xolciw"} data-styles-preset={"UkS9aeQXr"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1lwpl3i, var(--token-90b0a27a-9ddf-49aa-80fe-e304c114d331, rgb(251, 244, 235)))"}}>Link</motion.h5></React.Fragment>} className={"framer-1jgmw8w"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"bip_wxUzC"} style={{"--extracted-1lwpl3i": "var(--token-90b0a27a-9ddf-49aa-80fe-e304c114d331, rgb(251, 244, 235))"}} text={rhVAnbOgT} variants={{"qBmqbobG8-hover": {"--extracted-1lwpl3i": "var(--token-f43d90dc-3e16-4959-8e4a-bbe5b1411a0f, rgb(158, 227, 91))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"qBmqbobG8-hover": {children: <React.Fragment><motion.h5 className={"framer-styles-preset-xolciw"} data-styles-preset={"UkS9aeQXr"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1lwpl3i, var(--token-f43d90dc-3e16-4959-8e4a-bbe5b1411a0f, rgb(158, 227, 91)))"}}>Link</motion.h5></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qSG7z.framer-ys42lo, .framer-qSG7z .framer-ys42lo { display: block; }", ".framer-qSG7z.framer-gze6se { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-qSG7z .framer-1jgmw8w { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qSG7z.framer-gze6se { gap: 0px; } .framer-qSG7z.framer-gze6se > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-qSG7z.framer-gze6se > :first-child { margin-top: 0px; } .framer-qSG7z.framer-gze6se > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OhkRZ9NPN":{"layout":["auto","auto"]}}}
 * @framerVariables {"WFNbhH5HS":"tap","FflsjHJff":"link","rhVAnbOgT":"link1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervJRq0v6bv: React.ComponentType<Props> = withCSS(Component, css, "framer-qSG7z") as typeof Component;
export default FramervJRq0v6bv;

FramervJRq0v6bv.displayName = "Navigation Link";

FramervJRq0v6bv.defaultProps = {height: 19, width: 26};

addPropertyControls(FramervJRq0v6bv, {WFNbhH5HS: {title: "Tap", type: ControlType.EventHandler}, FflsjHJff: {title: "Link", type: ControlType.Link}, rhVAnbOgT: {defaultValue: "Link", displayTextArea: false, placeholder: "Write Link Here", title: "Link", type: ControlType.String}} as any)

addFonts(FramervJRq0v6bv, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})